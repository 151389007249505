@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);


/* @font-face {
  font-family: 'impactregular';
  src: url('../assets/fonts/unicode.impact-webfont.woff2') format('woff2'),
       url('../assets/fonts/unicode.impact-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

/* general settings */

html, body {
  height: 100%;
  overflow-x: hidden;
  overflow: hidden;
}

body {
  font-family: "Raleway";
  font-size: 19px;
  color: #000000;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  border: none;
  outline: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* GENERAL */
.NotMobile {
  display: inherit;
}

.OnlyMobile {
  display:none;
}

.drop {
  border: solid 3px white;
  padding: 15px;
}

/* typography */
/* set font families */
.Title, .SubTitle, .TitleInverted, .SubTitleInverted, .ButtonText .titleLine .subtitleLine {
  font-family: "Raleway", 'sans-serif';
}

/* set colors */
.Title, .SubTitle, p{
  color: black;
}

.TitleInverted, .SubTitleInverted,  .PInverted, .ButtonContainer{
  color: white;
}

/* set sizes */
.Title, .TitleInverted .titleLine{
  font-size: 80px;
  font-weight: 800;
}

.SubTitle, .SubTitleInverted, .ButtonText .subtitleLine {
  font-size: 50px; 
  font-weight: 700;
}

/* containers */
.Container {
  /* background-color: white; */
  padding: 10px;
  /* border: solid black 3px; */
  padding-left: 30px;
  padding-right: 30px;
}

.ContainerHeader {
  background-color: black;
  color: white;
  width: 100%;
  /* font-size: 2em; */
}

.wrapperHeader {
  max-width: 100%;
  overflow: hidden;
  height: 60px;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  -webkit-animation: marquee 300s linear infinite;
          animation: marquee 300s linear infinite;
  cursor: pointer;
}

.marquee div {
  display: inline-block;
  color: white;
}

@-webkit-keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.marquee:hover  {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

/* background colors */
body {
  background: white;
}

.ButtonContainer, .InvertedButtonContainer {
  width: auto;
  background: white;
  margin: 10px;
  padding: 10px;
  border: solid 3px black;
  cursor: pointer;
  color: black;
}

.InvertedButtonContainer {
  background: black;
  color: white;
}

 .ButtonContainer:hover {
  background: black;
  color: white;
} 

.InvertedButtonContainer:hover {
  background: black;
  color: white;
} 

.ButtonText {
  text-transform: uppercase;
}


/* images */
.ResponsiveImage {
  width: 100%;
}




/* HOME */

.homeWrapper {
  width: 100%;
  overflow: hidden; /* add this to contain floated children */
}

.homeTop, .homeBottom {
  width: 100%;
  height: calc(50vh - 30px);
  position: relative;
}

.homeBottom {
  cursor:pointer;
}

.homeTop {
  border-bottom: 10px solid black;
}

.homeTopText {
  transform: translate(10vw, 15vw);
}

.homeBottomText  {
  transform: translate(15vw, 15vw);
}

.homeRightText {
  position: "absolute";
  transform: translate(15vw, 30vw);
}

.homeBottomImage {
  position: absolute;
  z-index: -10;
  width: 15vw;
  transform: translate(5vw, 2vw);
  cursor:pointer;
}

.homeLeft, .homeRight {
  width: calc(50% - 5px);
  float:left; 
  height: calc(100vh - 60px);
}

.homeRight {
  border-left: 10px solid black;
  cursor:pointer;
}



/* ABOUT */

.aboutLeft, .aboutCenter, .aboutRight{
  width: calc(100%/3 - 6.666px);
  float:left; 
  height: calc(100vh - 60px);
}

.aboutLeft {
  border-right: 10px solid black;
  background-color: black;
}

.aboutCenter, .aboutLeft {
  /* background-color: green; */
  border-right: 10px solid black;
  overflow-y: auto;
  overflow-x: hidden;
}


.aboutTop, .aboutBotttom {
  width: 100%;
  height: calc(50vh - 30px);
  position: relative;
  cursor: pointer;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

}

.aboutTop {
  border-bottom: 10px solid black;
}

.centerText {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

/* SHOP */
.shopLeft, .shopCenter, .shopRight, .azerty {
  float:left; 
  height: calc(100vh - 60px);
}

.azerty {
  min-height: 100vh;
}

.extra {
  min-height: calc(200vh);
  height: calc(200vh);
  overflow-y: auto;
}

.shopLeft {
  width: calc(20vw) !important;
  overflow: hidden;
  max-height: 200px !important;
  height: 200px;
}

.shopCenter, .shopRight {
  width: calc(40vw - 20px) !important;
  border-left: 10px solid black;
  overflow-y: hidden;
}



.shopRightBottom, .checkoutBottom, .mHomeBottom {
  position: absolute;
  bottom: 0;
  width: calc(40vw);
  padding-top: 5px;
  height: 58px;
  color: white;
  background-color: black;
  text-align: center; 
}

.mHomeBottom {
  width: 100%;
  height: 55px;
}

.checkoutBottom {
  width: 50vw
}



.shopCenter, .azerty {
  overflow-y: auto;
  overflow-x: hidden;
}


/* banner */
.photobannerContainer {
  height: 100%;
  max-height: calc(100vh - 60px);
  background-color: black;
  position:absolute; 
  width: 100%;
  overflow:hidden;
}

.photobanner {
  position:absolute; 
  top:0px; 
  left:0px; 
  overflow:hidden; 
  white-space: nowrap;
  /* height: 100% !important; */
  background-color: black;
  -webkit-animation: bannermove 60s linear infinite;
          animation: bannermove 60s linear infinite;
}

.photobanner img {    
  margin: 0 5px;
  object-fit:fill;
}

.halfHeight {
  height: calc(50vh - 30px);
}

.fullHeight {
  height: calc(100vh - 60px);
}

@-webkit-keyframes bannermove {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-50%, 0);
  }
}

@keyframes bannermove {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-50%, 0);
  }
}

/* .photobanner:hover  { */
  /* animation-play-state: paused; */
/* } */

/* SVG */
/* svg:hover {
  fill: red;
} */


/* responsive */


@media screen and (max-width: 768px) {

  body {
    font-size: 16px;
  }

  .Title, .TitleInverted, .SubTitleInverted, .SubTitle {
    text-transform: uppercase;
  }

  .SubTitle, .SubTitleInverted {
    font-size: 25px;
  }

  .Title, .TitleInverted {
    font-size: 50px;
  }
  

  .Message {
    padding-left: 30px;
  }
  
  .HeaderContainer{
    width: 100%;
    height: auto;
    position: relative;
  }

  .headerImage {
    width: 100%;
    height: 100%;            
    position: relative;
    top: 0;
    left: 0;
  }  
  .OnlyMobile {
    display:inherit;
  }
  .NotMobile {
    display: none;
  }

  .wrapperHeader {
    height: 35px;
    padding-top: 5px;
  }

  .photobannerContainer {
    height: calc(50vh - 120px)
  }

  .halfHeight {
    height: calc(50vh - 120px);
  }

}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.Card {
  background: white;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: solid 3px black;
}

@media screen and (min-width: 768px)  {
  .mobile-break { display: none; }
  .MenuContainer {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

input[type="text"] { border: none }
input.middle:focus {
  outline-width: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* BluuNext */
@font-face {
  font-family: 'Bluu Next';
  src: local('Bluu Next'), url(/static/media/BluuNext-Bold.62617b94.otf);
}

/* Office Code PRO */
@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Regular.568927fb.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-RegularItalic.829ee485.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Medium.fa33df79.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-MediumItalic.e0d7ac01.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Light.8a5df412.woff) format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-LightItalic.027a6316.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Bold.ed2eb92b.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-BoldItalic.193d9358.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

